.breadcrumb_main_area {
    text-align: left;
}

.breadcrumb_main_area h1 {
    color: gray;
}

.breadcrumb_main_area span {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.38;
    color: #3b4858;
    margin:30px;
    margin-bottom: 70px;
    display: block;
}

.breadcrumb_main_area {
    text-align: center;
    background: -webkit-gradient(linear,left top,left bottom,from(#fff),to(#cdedfa));
    background: linear-gradient(to bottom,#fff,#cdedfa);
    /*background-image: url("../../public/assets/img/banniere.webp");*/
}

.row-bouton {
    width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
}

.bouton {
    border-radius: 10px;
    background-color: blue;
    -webkit-box-shadow: 0 3px 6px 0 rgba(0,0,0,.2);
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.2);
    font-size: 33px;
    font-weight: 700;
    line-height: .91;
    text-align: center;
    color: #fff;
    padding: 15px;
    margin-bottom: 15px;
    -webkit-transition: all .3s;
    transition: all .3s;
    position: relative;
}

.image-logo {
    position: absolute;
    right: 0;
    max-width: 250px;
}

.facilities_top_para p {
    color: black;
    text-align: justify;
    font-family: Poppins,sans-serif;
    font-size: 20px;
}

/*p {*/
    /*padding-bottom: 10px;*/
/*}*/

.numero {
    background: #b2d6ee;color: #fff;border: 1px solid #b2d6ee;border-radius: 50%;display: inline-block; padding: 11px 22px 11px 18px; text-align: center;
    margin-bottom: 10px;
}

section {
    padding-top: 25px;
    padding-bottom: 25px;
}

.texte-comment {
    background-color: lightgrey;
    padding-top: 10px;
    padding-left: 8px;
    padding-right: 8px;
}

.about_us_area p {
    color:black;
}

.ile-de-france {
    margin-top: -30px;
}

.avis {
    border: lightgrey solid; margin-bottom: 30px
}

.avis_container {
    width: 75%; margin-right: auto; margin-left: auto
}

.avis-content {
    padding: 15px;
    border: lightgrey solid;
}

.avis-content p {padding: 0px}

.review {
    padding: 0px
}


.container-bouton {
    position: relative;
    width: 100%;
}

.container-bouton .btn {
    top: 50%;
    right: 10%;
    background-color: #1ec034;
    color: white;
    font-size: 30px;
    border-radius: 15px;
    border-color: whitesmoke;
    cursor: pointer;
    position: absolute;
    text-decoration: underline;
}

.contact_us h6, .contact_us p {
    color: dimgrey;
}

.testimonials-item {
    margin: 0 0 20px;
    padding: 24px 0 0;
    text-align: center
}

.testimonials-item .testimonials-username {
    font-size: 18px;
    font-weight: 500;
    margin: 25px 0 5px;
    color: #fff
}

@media (max-width:767px) {
    .testimonials-item .testimonials-username {
        margin-top: 15px
    }
}

.testimonials-item .testimonials-text {
    position: relative;
    color: #fff;
    font-size: 24px;
    line-height: 1.5em;
    font-style: italic;
    font-weight: 100
}

@media (max-width:767px) {
    .testimonials-item .testimonials-text {
        font-size: 20px;
        padding: 0 15px
    }
}

@media (max-width:767px) {
    .testimonials {
        margin: 0 0 15px
    }
}

.testimonials-carousel.slick-slider {
    padding: 0 0 45px
}

@media (max-width:767px) {
    .testimonials-carousel.slick-slider {
        padding: 0 0 15px
    }
}

.testimonials-carousel.slick-slider .slick-dots {
    margin-bottom: -15px
}


@media (max-width:767px) {
    .testimonials-carousel.slick-slider:after,
    .testimonials-carousel.slick-slider:before {
        left: -40px;
        font-size: 32px;
        top: 0
    }
}

.testimonials-carousel.slick-slider:after {
    left: auto;
    right: -120px;
    content: "\e911"
}

@media (max-width:767px) {
    .testimonials-carousel.slick-slider:after {
        right: -40px;
        top: auto;
        bottom: 0
    }
}

.testimonials-box {
    width: 33.333%;
    margin-bottom: 30px
}

@media (max-width:991px) {
    .testimonials-box {
        width: 50%
    }
}

@media (max-width:665px) {
    .testimonials-box {
        width: 100%
    }
}

.testimonials-box .inside {
    height: 100%;
    padding: 30px 28px;
    text-align: left;
    background: rgba(255, 255, 255, .85);
    -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, .12);
    -moz-box-shadow: 0 0 35px rgba(0, 0, 0, .12);
    box-shadow: 0 0 35px rgba(0, 0, 0, .12);
    color: #252936;
    position: relative;
    margin: 0 15px 0;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.testimonials-box-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
    position: relative;
    margin-bottom: 20px;
    color: #000
}

@media (max-width:767px) {
    .testimonials-box-title {
        font-size: 18px
    }
}

.etoile {padding-bottom: 20px;}
.inside .fa {
    color: yellow;
    font-size: 20px;
    text-shadow: 0 0 3px #000;}



.testimonials-box-text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px
}

.testimonials-box-username {
    margin-top: auto;
    font-size: 18px;
    line-height: 28px;
    font-weight: 300
}

@media (max-width:1199px) {
    .testimonials-box-username {
        font-size: 16px
    }
}

.testimonials-grid {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px
}

#Avis6 , #Avis7 , #Avis8,  #Avis9, #Avis10, #Avis11, #Avis12, #Avis13, #Avis14
, #Avis15, #Avis16, #Avis17, #Avis18, #Avis19, #Avis20, #Avis21{
    display:none;
}

.vide {display:none}

.pagination { margin-bottom: 20px;
}

.bouton-telephone {
    position: absolute;
    right: 10px
}

.bouton-devis {
    position: absolute;
    left: 10px
}

/*.row-bouton {*/
    /*margin-bottom: 60px;*/
/*}*/

@media (max-width:767px) {
    .bouton {
        position: inherit;
        right: 0;
        left: 0;
    }

    p {
        margin-left: 10px;
        margin-right: 10px;
    }

}

.about_us_area .container {
    padding-right: 0px;
    padding-left: 0px;
}


.breadcrumb_main_area {
    padding-bottom:80px;
}

.about_us_area { margin-top: 40px}