.ville-liste {
    font-size: 16px;
    line-height: 1.25;
    color: #979797;
    border-radius: 3px;
    background-color: #fff;
    border: solid 1px #f2f2f2;
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.capitalize {
    text-transform: capitalize;
}