.top3 p {
    font-size: 16px;
    color: dimgrey;
}

.top3 .addr {
    border-bottom: dimgrey solid 1px;
    margin-bottom: 15px;
}

.top3

{
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;

}